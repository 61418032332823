(function() {
  var s = document.getElementsByTagName("script");
  var d = s[s.length - 1].src.substring(
    0,
    s[s.length - 1].src.lastIndexOf("/") + 1
  );
  for (var i = 0; i < arguments.length; i++) {
    document.write(
      '<script type="text/javascript" src="' + d + arguments[i] + '"></script>'
    );
  }
})(
  "jquery.easing.1.3.js",
  "jquery-1.9.1.js",
  "animatedscrolltotop.js",
  "tab.js",
  "slick.min.js",
  "bootstrap/popper.js",
  "bootstrap/bootstrap.min.js",
  "bootstrap/bootstrap.bundle.min.js",
  "css3-mediaqueries.js",
  "collapser.js",
  "modernizr.custom.js"

);
